import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function E404() {
    const navigate = useNavigate();

    async function handleClick() {
        navigate("/");
    }

  return (
    <>
        <div>404 - Página não encontrada</div>
        <button onClick={handleClick}>Voltar para o Início</button>
    </>
  )
}
