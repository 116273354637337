import styled from "styled-components";

export const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    gap: 10px;

    h2 {
        color: white;
        font-size: 18px;
        font-weight: 400;
        font-family: 'Fira Code', monospace;
    }

    img {
        width: 60px;
    }
`;