import React, { useRef, useState } from 'react'
import { CardRegister, Wrapper, Inputs, CardContent, AvatarProgress, ChangeAvatar, InputPwd } from './css.register'
import AVATAR from '../../storage/default_avatar.png'
import { data } from '../../data/ecosystem'
import { Back } from './css.Login'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { storage } from "../../firebase";
import { v4 as uuid } from 'uuid'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LOADING from '../../storage/loading2.gif'
import { Alert, Snackbar } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom'

export default function Register() {
    const [seenPwd, setSeenPwd] = useState(false);
    const [typing, setTyping] = useState(false);
    const [nickname, setNickname] = useState();
    const [img, setImg] = useState();
    const [error, setError] = useState({ status: false, message: null, type: null })
    const [started, setStarted] = useState(false);
    const [myProgress, setMyProgress] = useState();
    const [loading, setLoading] = useState(false);

    const imgRef = useRef();
    const navigate = useNavigate();

    const SeenPwd = () => {
        setSeenPwd(!seenPwd);
      }
    
      const handleTyping = (e) => {
        if (e.target.value.length >= 1) {
          setTyping(true);
        } else {
          setTyping(false);
          setSeenPwd(false)
        }
    }

    const handleNickname = (e) => {
        setNickname(e.target.value);
    }
    
    const handleImg = async (e) => {
        const storageRef = ref(storage, uuid());
        const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);
        if (e.target.files.length >= 1) {
            setStarted(true)
            const validation = ["png", "jpg", "webp", "gif", "jpeg"];

            if (!validation.includes(e.target.files[0].type.replace("video/", "").replace("image/", "").trim())) {
                setTimeout(() => {
                    setStarted(false)
                    setError({ status: true, message: `Formato '${e.target.files[0].type.replace("image/", "").replace("video/", "").trim()}' não suportado!`, type: "error" })
                }, 1000)

                setTimeout(() => setError({ status: false, message: null, type: null }), 5000);
                return true;
            }

            uploadTask.on('state_changed', 
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + String(progress).split('.')[0] + '% done');
                setMyProgress(progress);
                switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                }

                if (progress === 100) {
                    console.log(`Upload format ${e.target.files[0].type}`);
                    setTimeout(() => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setImg(downloadURL);
                            setStarted(false);
                        });
                    }, e.target.files[0].type === "image/gif"? 1500 : 1000)
                }
            }, 
            (error) => {
                setImg(null);
                setStarted(false);
            });
        }
    }

    const handleBack = () => {
        navigate("/");
      }

  return (
    <Wrapper>
        <CardRegister>
            <Back style={{ display: loading? "none" : "flex" }} onClick={handleBack}>
            <KeyboardBackspaceIcon />
            <h3>Início</h3>
          </Back>
            <CardContent>
                <input disabled={started} ref={imgRef} onChange={handleImg} type="file" accept="image/png, image/gif, image/jpeg, image/webp, image/jpg" style={{display: "none", alignSelf: "center" }} id="file"/> 
                <label htmlFor="file">
                    {started && (
                        <>
                            <AvatarProgress>
                                <div />
                                <span>{`${String(myProgress).split('.')[0]}` || 0}%</span>
                                <img src={LOADING} alt="" />
                            </AvatarProgress>
                        </>
                    )}
                    {!started && (
                        <ChangeAvatar>
                            <img src={img ? img : AVATAR} alt="" />
                            <span>Mudar avatar</span>
                        </ChangeAvatar>
                    )}
                </label>
                <h1>{nickname ? nickname : "REGISTRO"}</h1>
                <Inputs>
                    <input onChange={handleNickname} type="text" placeholder='Apelido'/>
                    <input type="email" placeholder='Email'/>
                    <InputPwd>
                        <input type={seenPwd? "text" : "password"} onChange={handleTyping} placeholder='Senha' />
                        {typing && (seenPwd? <VisibilityOffIcon onClick={SeenPwd} /> : <VisibilityIcon onClick={SeenPwd} />)}
                    </InputPwd>
                    <button>Registrar-se</button>
                    <h4>Já possuí uma conta? <a href='/login'><span>Clique aqui</span></a></h4>
                </Inputs>
            </CardContent>
        </CardRegister>
        <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={error?.status} autoHideDuration={6000}>
          <Alert severity={String(error?.type || "info")} sx={{ width: '100%' }}>{error?.message}</Alert>
        </Snackbar>
    </Wrapper>
  )
}