import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAPgQ7o7KonH8RuyX95TcCJpRCAnPZ_f6Q",
  authDomain: "lienfamoso.firebaseapp.com",
  projectId: "lienfamoso",
  storageBucket: "lienfamoso.appspot.com",
  messagingSenderId: "91674630340",
  appId: "1:91674630340:web:84b27985c4fbb33fcb1191"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();