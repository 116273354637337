import React, { useRef, useState } from 'react'
import { data } from '../../data/ecosystem'
import { Wrapper, CardLogin, CardContent, InputPwd, LogIn, Back } from './css.Login'
import Logo from '../../storage/logo.png'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Alert, Snackbar } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';
import landing_page from '../Misc/Loading';

export default function Login({ socket }) {
  const [seenPwd, setSeenPwd] = useState(false);
  const [typing, setTyping] = useState(false);
  const [status, setStatus] = useState({ status: false, message: null, type: null });
  const [loading, setLoading] = useState(false);

  const pwdRef = useRef();
  const navigate = useNavigate();

  const SeenPwd = () => {
    setSeenPwd(!seenPwd);
  }

  const handleTyping = (e) => {

    if (e.target.value.length >= 1) {
      setTyping(true);
    } else {
      setTyping(false);
      setSeenPwd(false)
    }
  }

  const handleLogin = async () => {
    const PWD = pwdRef.current.value;

    if (PWD === "123456") {
      setLoading(true);
      setTimeout(() => {
        setStatus({ status: true, message: `Sucesso! Redirecionando..`, type: "success" });
      }, 1500);

      setTimeout(() => {
        setStatus({ status: false, message: null, type: null })
        socket.emit("sendLogin", { user: "@admin", password: PWD, status: true });

        navigate("/dashboard");
      }, 3000);
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setStatus({ status: true, message: `Senha inválida.`, type: "error" })
      }, 1500);
      setTimeout(() => {
        setStatus({ status: false, message: null, type: null })
      }, 3000);
    }
  }

  const handleBack = () => {
    navigate("/");
  }

  return (
    <Wrapper>
        <CardLogin>
          <Back style={{ display: loading? "none" : "flex" }} onClick={handleBack}>
            <KeyboardBackspaceIcon />
            <h3>Início</h3>
          </Back>
          <CardContent>
          {loading && landing_page()}
          {!loading && (
            <>
              <img src={Logo} alt="" />
              <h1>LOGIN</h1>
              <input className='InputUser' type="email" placeholder='Email'/>
              <InputPwd>
                <input type={seenPwd? "text" : "password"} ref={pwdRef} onChange={handleTyping} placeholder='Senha' />
                {typing && (seenPwd? <VisibilityOffIcon onClick={SeenPwd} /> : <VisibilityIcon onClick={SeenPwd} />)}
              </InputPwd>
              <LogIn>
                <button onClick={handleLogin}>Entrar</button>
                {/* <h4>No momento apenas <span>Administradores</span>.</h4> */}
                <h4>Não possuí uma conta? <a href='/register'><span>Clique aqui</span></a></h4>
              </LogIn>
            </>
            )}
          </CardContent>
        </CardLogin>
        <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={status?.status} autoHideDuration={6000}>
          <Alert severity={String(status?.type || "info")} sx={{ width: '100%' }}>{status?.message}</Alert>
        </Snackbar>
    </Wrapper>
  )
}