import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to top, hsla(0, 0%, 0%, .125) 50%, hsla(0, 0%, 0%, .75));
`;

export const CardLogin = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 450px;
    height: 400px;
    border-radius: 10px;
    box-shadow: 0px -10px 10px #0000005c;
    background-image: linear-gradient(to top,hsl(234deg 38% 5%) 10%,hsla(0,0%,0%,.75));

    h1 {
        color: white;
        font-family: 'Kdam Thmor Pro', sans-serif;
    }
`;

export const CardContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    animation: float 1.4s ease-in-out 1;

    input.InputUser {
        width: 240px;
        padding: 10px 0px 10px 8px;
        border: none;
        outline: none;
        color: white;
        border-radius: 5px;
        margin-bottom: 10px;
        background-color: #3636364d;
        border: 1px solid transparent;

        &::placeholder {
            color: #ffffff7a;
        }

        &:invalid {
            border: 1px solid red;
        }
    }

    input:not(.InputUser) {
        width: 240px;
        padding: 10px 0px 10px 8px;
        border: none;
        outline: none;
        color: white;
        border-radius: 5px;
        margin-bottom: 10px;
        background-color: #3636364d;
        border: 1px solid transparent;
        
        &::placeholder {
            color: #ffffff7a;
        }

        &:invalid {
            border: 1px solid red;
        }
    }

    img {
        width: 85px;
    }

    @keyframes float {
        0% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            opacity: 0;
        }
        100% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            opacity: 1;
        }
    }
`;

export const LogIn = styled.div`
    display: flex;
    flex-direction: column;

    button {
        width: 247px;
        height: 40px;
        font-weight: bold;
        color: white;
        font-family: 'Barlow', sans-serif;
        border-radius: 5px;
        text-shadow: 0px 0px 10px #177650;
        background-image: linear-gradient(to right,hsl(156deg 76% 41%) 10%,hsl(155deg 100% 62%));
        border: 1px solid transparent;

        &:hover {
            cursor: pointer;
        }
    }

    h4 {
        font-family: 'Inconsolata', monospace;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff5e;
        margin-top: 5px;
        opacity: 0.9;

        a {
            text-decoration: none;
        }

        span {
            color: #6d9986;

            &:hover {
                cursor: pointer;
            }
        }
    }
`;

export const InputPwd = styled.div`
    display: flex;
    position: relative;
    
    svg {
        position: absolute;
        right: 5px;
        top: 7px;
        color: #7a7a7ab3;
    }
`;

export const Back = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    gap: 5px;
    top: 10px;
    left: 10px;
    animation: float 1.4s ease-in-out 1;

    @keyframes float {
        0% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            opacity: 0;
        }
        100% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            opacity: 1;
        }
    }

    h3 {
        color: #ffffff94;
        font-size: 17px;
        font-family: 'Inconsolata', monospace;
    }

    svg {
        color: #79fac3;
    }

    &:hover {
        cursor: pointer;
    }
`;