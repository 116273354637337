import React from 'react'
import { useNavigate } from 'react-router-dom'
import landing_page from './Misc/Loading';

export default function Admin({ channel_data, total, data }) {
  const navigate = useNavigate();

  async function handleClick() {
      navigate("/");
  }

  return (
    <>
      <h1>Administração</h1>
      <button onClick={handleClick}>Início</button>
      <h2>Há {total || ".."} usuário{total >= 2? "s" : ""} online no site</h2>
      <h2>{data?.length} canais:</h2>
      <h2>{data?.channels.map((ch, k) => (
        <div key={k}>{ch.last_video.author.name} - {ch.id}</div>
      ))}</h2>
    </>
  )
}