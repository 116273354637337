import React from 'react'
import LOADING from '../../storage/loading.gif'
import { LoadingWrapper } from './css.loading.js'

function landing_page() {
    return (
        <LoadingWrapper>
            <img src={LOADING} alt="" />
            <h2>Carregando</h2>
        </LoadingWrapper>
    )
};

export default landing_page;
