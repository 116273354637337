import React from 'react'
import { useNavigate } from 'react-router-dom'
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import landing_page from './Misc/Loading';

export default function Home({ channel_data, total, notification, setNotification }) {
  const navigate = useNavigate();

  async function handleClick() {
      navigate("/admin");
  }

  const closeNotification = async () => {
    await setNotification(false);
  }

  return (
    <>
      <div onClick={closeNotification}>
      {!notification? <NotificationsIcon style={{ color: "black" }}/> 
      : <NotificationsActiveIcon style={{ color: "red" }}/>}</div>
      <h1>Home</h1>
      <button onClick={handleClick}>Administração</button>
    </>
  )
}