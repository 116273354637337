import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to top, hsla(0, 0%, 0%, .125) 50%, hsla(0, 0%, 0%, .75));
`;

export const CardRegister = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 450px;
    height: 400px;
    border-radius: 10px;
    box-shadow: 0px -10px 10px #0000005c;
    background-image: linear-gradient(to top,hsl(234deg 38% 5%) 10%,hsla(0,0%,0%,.75));
`;

export const CardContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    animation: float 1.4s ease-in-out 1;

    h1 {
        color: white;
        font-family: 'Kdam Thmor Pro', sans-serif;
    }

    @keyframes float {
        0% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            opacity: 0;
        }
        100% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            opacity: 1;
        }
    }
`;

export const Inputs = styled.div`
    display: flex;
    gap: 10px;
    width: 250px;
    flex-direction: column;

    input {
        width: 240px;
        padding: 10px 0px 10px 8px;
        border: none;
        outline: none;
        color: white;
        border-radius: 5px;
        background-color: #3636364d;
        border: 1px solid transparent;

        &::placeholder {
            color: #ffffff7a;
        }

        &:invalid {
            border: 1px solid red;
        }
    }

    button {
        border: none;
        outline: none;
        border-radius: 5px;
        padding: 10px 10px;
        color: white;
        text-shadow: 0px 0px 10px #177650;
        background-image: linear-gradient(to right,hsl(156deg 76% 41%) 10%,hsl(155deg 100% 62%));
        border: 1px solid transparent;

        &:hover {
            cursor: pointer;
        }
    }

    h4 {
        font-family: 'Inconsolata', monospace;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff5e;
        margin-top: -3px;
        opacity: 0.9;

        a {
            text-decoration: none;
        }

        span {
            color: #6d9986;

            &:hover {
                cursor: pointer;
            }
        }
    }
`;

export const AvatarProgress = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none;
    user-select: none;

    div:first-child {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: #141419;
    }

    img:last-child {
        position: absolute;
        z-index: 2;
        width: 30px;
        height: 30px;
        margin-top: -30px;
        pointer-events: none;
    }

    span {
        position: absolute;
        z-index: 2;
        color: white;
        margin-top: 40px;
        pointer-events: none;
        font-family: 'Inconsolata', monospace;
    }
`;

export const InputPwd = styled.div`
    display: flex;
    position: relative;
    
    svg {
        position: absolute;
        right: 7px;
        top: 6px;
        color: #7a7a7ab3;
    }
`;

export const ChangeAvatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none;
    user-select: none;

    span {
        display: none;
        position: absolute;
        color: white;
        max-width: 60px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 600;
        font-size: 10px;
        font-family: 'Kdam Thmor Pro', sans-serif;
    }

    img {
        position: relative;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        object-fit: cover;
        pointer-events: none;
    }

    &:hover {
        cursor: pointer;

        img {
            opacity: 0.4;
            pointer-events: none;
        }

        span {
            display: block;
        }
    }
`;