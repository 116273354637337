import { BrowserRouter, Routes, Route } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import io from 'socket.io-client';
import Home from './pages/Home';
import Admin from './pages/Admin';
import E404 from './pages/Errors/E404';
import Dashboard from './pages/components/Dashboard';
import Register from './pages/Welcome/Register';
import Login from './pages/Welcome/Login';
import landing_page from './pages/Misc/Loading';

const socket = io.connect("https://yt-bot-v4-backend.vercel.app:5001/")

function App() {
  const [total, setTotal] = useState();
  const [channel_data, setChannel_data] = useState();
  const [notification, setNotification] = useState(false);

  useEffect(() => {
    return () => {
      setInterval(() => { 
        socket.emit("send_allSockets");
        socket.emit("onPublish");
        socket.emit("sendNotifications");
      }, 1000)
    }
  }, [socket])
  
  useEffect(() => {
    return () => {
      socket.on("receive_allSockets", (data) => {
        setTotal(data.count);
      })

      socket.on("createPublish", (data) => {
        setChannel_data(data);
      })

      socket.on("getNotifications", async () => {
        setNotification(true);
      })

    }
  }, [socket])

  return (
  <BrowserRouter>
      {channel_data === undefined && total === undefined? (
        landing_page()
      ) : (
      <Routes>
        <Route exact path="/" index element={(
          <Home channel_data={channel_data} total={total} notification={notification} socket={socket} setNotification={setNotification}/>
        )} />
        <Route exact path="/admin" index element={<Admin channel_data={channel_data} total={total} socket={socket} data={channel_data}/>} />
        <Route exact path="*" socket={socket} index element={(<E404 />)} />
        <Route exact path="/register" socket={socket} index element={(<Register />)} />
        <Route exact path="/login" socket={socket} index element={(<Login />)} />
        <Route exact path="/dashboard" socket={socket} index element={<Dashboard />} />
      </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
